/* Modal backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 10px;
  }
  
  /* Modal container */
  .modal-container {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Modal header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
    margin-top: 0%;
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    padding: 0px;
    margin: 0px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #999;
  }
  
  .close-button:hover {
    color: #333;
  }
  
  /* Modal body */
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .modal-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .modal-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Modal footer */
  .modal-footer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .modal-submit {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  
   
    
  }
  
  .modal-submit:hover {
    background-color: #0056b3;
  }
  
  .modal-cancel {
    background-color: #6c757d;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .modal-cancel:hover {
    background-color: #5a6268;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Media queries for mobile responsiveness */
  @media (max-width: 600px) {
    .modal-container {
      padding: 15px;
      width: 80%;
      max-width: 90%;
      border-radius: 8px;
    }
  
    .modal-title {
      font-size: 16px;
    }
  
    .modal-input {
      font-size: 14px;
    }
  
    .modal-submit, .modal-cancel {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  