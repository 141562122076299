.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent backdrop */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    z-index: 1050;
}

.modal-content {
    background-color: white;
    width: 90%;
    max-width: 600px; /* Adjust max width as necessary */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    position: relative; /* Needed for absolute positioning of the close button */
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px; /* Reduced padding */
    padding-top: 0px;
    border-bottom: 1px solid #ccc; /* Optional divider */
    /* Ensure there are no excessive margins affecting the size */
}

.close-button {
    border: none;
    background: none;
    color: #aaa;
    font-size: 40px; /* Large 'X' icon */
    cursor: pointer;
}

.close-button:hover {
    color: #777;
}

.modal-body {
    padding: 10px;
}
