.dashboard-container {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding-top: 60px;
    background-color: #f4f4f4;
    border-radius: 8px;
    margin-top: 20px;
}

.metrics {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.metric {
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.metric label {
    font-weight: bold;
}

.metric span, .metric ul {
    margin-left: 10px;
}

.metric ul {
    list-style-type: none;
    padding: 0;
}

.error {
    color: white;
}
