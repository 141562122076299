*{
  /* margin:10; */
  padding:0;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
   
    background-color: #fff;
    padding: 10px;

    border-radius: 8px;
    width: 400px;
    /* max-width: 80%; */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    /* padding-bottom: 90px */
    padding-bottom: 60px;
  }
  
  .modal-content h2 {
    margin-bottom: 15px;
  }
  
  .modal-content label {
    display: block;
    margin-top: 0px;
    margin-bottom: 5px;
   
    font-weight: bold;
  }
  
  .modal-content input,
  .modal-content select {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    
  }
  
  
  .submit  {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    /* margin-left: 0; */
    /* margin-right: 80px; */
   position: absolute;
  
   left: 10px;
    bottom: 20px;
  }
  .cancel  {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #67696b;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    position : absolute;
   right: 10px;
   
    bottom: 20px;
  }

  
  .modal-content button:hover {
    background-color: #0056b3;
  }
  
  .modal-content button.cancel {
    background-color: #6c757d;
  }
  
  .modal-content button.cancel:hover {
    background-color: #5a6268;
  }
  .modal-content button.submit {
    background-color: #007bff;
    left: 20px;
  }
  .modal-content button.cancel {
    background-color: #6c757d;
    right: 20px;
  }

  .received {
    background-color: #28a745; /* Green */
    color: #fff;
  }
  
  .pending {
    background-color: #ffc107; /* Yellow */
    color: #fff;
  }
  
  /* Optional: Add hover styles */
  .received:hover {
    background-color: #218838;
  }
  
  .pending:hover {
    background-color: #e0a800;
  }
  .form-group {
    display: flex;
    align-items: center; /* Align items vertically centered */
    margin-bottom: 10px; /* Adjust spacing between groups */
  }
  
  .form-group label {
    margin-right: 10px; /* Space between label and value */
    min-width: 100px; /* Adjust based on your design */
  }
  
 
  