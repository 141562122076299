.sales-overview-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .sales-overview-card .main-content {
    margin-bottom: 20px;
  }
  
  .sales-overview-card .title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .sales-overview-card .value {
    font-size: 28px;
    font-weight: bold;
    color: #007bff;
  }
  
  .sales-overview-card .sub-content-container {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
  
  .sales-overview-card .sub-content-left,
  .sales-overview-card .sub-content-right {
    flex-basis: 45%;
  }
  
  .sales-overview-card .sub-content-left div,
  .sales-overview-card .sub-content-right div {
    font-size: 16px;
    color: #666;
  }
  