.hospitalPortalContainer {
  padding-top: 100px;
  background-color: #f9f9f9;
  font-family: 'Arial', sans-serif !important; /* Consistent font family across the portal */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 2px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 14px !important; /* Adjusted font size for readability */
  color: #333 !important; /* Text color for contrast */
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold !important; /* Bold font for table headers */
}

.table tr:hover {
  background-color: #f5f5f5;
}

.button {
  padding: 5px 10px !important;
  background-color: #4CAF50 !important;
  color: white !important;
  border: none !important;
  cursor: pointer !important;
  border-radius: 2px !important;
  box-shadow: 1px 1px 2px #554e4e !important;
  font-size: 14px !important; /* Font size for button text */
  font-family: 'Arial', sans-serif !important; /* Consistent font family */
  font-weight: bold !important; /* Bold text for emphasis */
  width: auto !important; /* Adjust width to fit text */
  display: inline-block !important; /* Ensures the button size adjusts to content */
}

.button:hover {
  background-color: #45a049 !important;
}

.react-modal-content {
  width: 500px;
  margin: auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 14px !important; /* Font size for modal content */
  color: #333 !important; /* Text color for modal content */
  font-family: 'Arial', sans-serif !important; /* Consistent font family */
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.accepted, .rejected, .pending, .download-button { /* Common styles for all buttons */
  font-size: 13px !important; /* Consistent font size for status buttons */
  font-family: 'Arial', sans-serif !important; /* Consistent font family */
  font-weight: bold !important; /* Bold text for emphasis */
  width: auto !important; /* Adjust width to fit text */
  padding: 5px 10px !important; /* Consistent padding */
  display: inline-block !important; /* Ensures the button size adjusts to content */
  cursor: pointer !important;
}

.accepted {
  background-color: #4CAF50 !important;
  color: white !important;
}

.accepted:hover {
  background-color: #07c00e !important;
}

.rejected {
  background-color: #f1564b !important;
  color: white !important;
}

.rejected:hover {
  background-color: #be1105 !important;
}

.pending {
  background-color: #9b9fa1 !important;
  color: #fdfdfd !important;
}

.pending:hover {
  background-color: #554e4e !important;
}

.download-button {
  background-color: #007BFF !important; /* Blue background color for the button */
  color: white !important; /* White text color for contrast */
  border: none !important; /* Remove default border */
  border-radius: 4px !important; /* Rounded corners */
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2) !important; /* Subtle shadow for depth */
  transition: background-color 0.3s ease !important; /* Smooth transition on hover */
}

.download-button:hover {
  background-color: #0056b3 !important; /* Darker blue on hover */
}

