/* HospitalEmailModal.module.css */
.modal-content {
  margin-top: 0px;

    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
     position: relative;
    top: 20%;
    transform: translateY(-20%);
    width: 400px;
    padding: 0 20px 20px 20px;
  }
  
  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  
  .close-button {
    background: none;
    border: none;
    color: #aaa;
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #000;
    background: red;
  }
  
  .modal-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input[type="email"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
  }
  
  .submit-button {
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    padding: 10px;
    text-align: center;
  }
  
  .submit-button:hover {
    background-color: #218838;
  }
  