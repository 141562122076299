/* PreviewForm.css */
.preview-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
}

.preview-header {
  text-align: center;
  margin-bottom: 20px;
}

.preview-section {
  margin-bottom: 20px;
}

.preview-section h3 {
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.preview-details {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Add space between label-info pairs */
}

.preview-row {
  display: flex;
  flex-direction: column; /* Stack label and info vertically */
  margin-bottom: 10px; /* Add space between rows */
}

.preview-row strong {
  text-align: left;
  margin-bottom: 4px; /* Space between label and value */
  font-weight: bold;
  font-size: 16px;
}

.preview-row span {
  text-align: left;
  padding-left: 10px; /* Optional padding for better alignment */
  word-wrap: break-word; /* Allows long text to wrap within the container */
  font-size: 15px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .preview-form {
    padding: 10px;
  }

  .preview-section h3 {
    font-size: 1.2em;
  }

  .preview-row strong {
    font-size: 1em;
  }

  .preview-row span {
    font-size: 0.9em;
  }

  .preview-buttons {
    flex-direction: column;
    align-items: stretch; /* Full-width buttons */
  }

  .sales-form-button-back {
    margin-bottom: 10px;
    /* width: 100%; Make button full-width */
  }
}
