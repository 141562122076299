/* TestResultModal.module.css */

.modalContent {
    position: relative;
    width: 400px;
    margin: 0 auto;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 100px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modalHeader {
    padding: 0px;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
    margin: 0px;
  }
  
  .formGroup {
    margin-bottom: 10px;
  }
  
  .formGroup label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .formGroup select,
  .formGroup input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .buttonGroup button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .buttonGroup button:hover {
    background-color: #007BFF;
    color: white;
  }
  
  .cancelButton {
    background-color: #f0f0f0;
  }
  
  .saveButton {
    background-color: #007BFF;
    color: white;
  }
  
  .saveButton:hover {
    background-color: #0056b3;
  }
  
  .cancelButton:hover {
    background-color: #d9d9d9;
  }
  .closeButton {
    position: absolute;
    top: 8px;
    right: 10px;
    background: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #474b47;
  }
  .closeButton:hover {
  
    background: #d40808;
  }