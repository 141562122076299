/* LogoutButton.css */
.logout-btn-container {
    display: flex;
    justify-content: center;  /* Center the button in its container */
    align-items: center;
    margin-right: 20px;  /* Adds some right margin if necessary */
}

.logout-button {
    background-color: #D32F2F; /* Vivid red color for visibility */
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.logout-button:hover {
    background-color: #C62828; /* Slightly darker shade on hover */
}

.logout-button:disabled {
    background-color: #E0E0E0; /* Gray out when disabled */
    color: #9E9E9E;
    cursor: not-allowed;
}
