/* VerticalStepper.css */

.vertical-stepper {
    border-radius: 15px;
    width: 300px;
    position: fixed;
    top: 110px; 
    left: 26px;
    background-color:blue;
    /* height: 200px; */
  }
  
  .step {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 10px;

    /* border-left: 2px solid #ccc; */
    margin-bottom: 10px;
    /* line-height: 40px; */
    color:blue;
    padding-left: 20px;
    padding-top: 15px;

    
  }
  /* .step.completed {
   
    width: 2px;
  }  */
  
  .step.completed .step-number,
  .step.active .step-number {
    background-color: white; /* White background for active and completed steps */
    color: black; /* Black text color for better contrast */
    /* border-left: #ccc; */
  }
  
 
  .step-number {
    min-width: 30px;
    height: 30px;
    border-radius: 40%;
    border: 2px solid white;
    
    /* background-color:blue; */
    color: #fff;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    padding: 5px;
    /* justify-content: flex-start; */
 
  }
  .step-text{
    word-wrap: break-word;
    text-align: left;
    padding: 5px;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: 600;
    color: #ccc;
  }
  /* .step.step.active{
    border-left: 2px solid #ccc;
    
  } */

 
  .step.active::after {
    content: '';
    position: absolute;
    width: 2px;
    height:var(--step-height);
    background-color: white; /* Line color */
    left: 42px; /* Align with the step number */
    top: 30px; /* Start just below the step number */
    z-index: -1;    
  }
 
  .step.completed:last-child::after {
    display: none; /* Hide the line for the last completed step */
}

@media (max-width: 768px) {
  .vertical-stepper {
    display: none;
  }
}
 
  