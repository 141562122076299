/* SalesDetailsPage.css */

@media print {
  .table {
    overflow: visible;
    height: auto !important;
    width: auto !important;
  }
}
main.table {
  width: 100%;
  height: auto;
  background-color:white;
  backdrop-filter: blur(7px);
  box-shadow: 0 .4rem .8rem #0005;
  border-radius: .8rem;
  overflow: hidden;
  overflow-x: auto;
}

.sales-details-container {
  margin: 15px;
  font-family: Arial, sans-serif;
  background-color: white;
  overflow-x: auto; /* Add horizontal scrolling */
}

.table-container {
  position: relative;
  overflow-x: auto;
}

.sales-table {
  width: 100%; /* Make the table use the full width of its container */
  border-collapse: collapse;
  margin: 2px auto; /* Center the table horizontally */
  padding: 10px;
  
}

.sales-table th, .sales-table td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 10px;
  height: 20px;
  width: 40px;
  font-size: 12px; /* Make the text smaller */
  overflow: hidden; /* Ensure text does not overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.sales-table th {
  background-color: #009879;
  color: white;
  height: 40px; /* Adjust the height as needed */
  line-height: 40px; /* Ensures text is vertically centered */
  overflow: hidden; /* Ensures text does not overflow */
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  padding: 0 10px; /* Adjusts padding to maintain alignment */
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure the sticky header is above other elements */
}

.sales-table tr {
  height: 20px; /* Ensure table cells have auto height */
  
  
  
}
.sales-table tr:hover,
.sales-table tr:nth-child(even):hover {
  background-color: rgb(221, 220, 220) !important; /* Change to your desired hover color */
}


.sales-table tr:nth-child(even) {
  background-color: rgba(236, 236, 236, 0.473);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.search-container {
  display: flex; /* Use flexbox for layout */
  justify-content: flex-end; /* Align children to the right */
  align-items: center; /* Vertically align the content */

  padding: 10px 20px; /* Padding around the container */
  border-radius: 5px; /* Rounded corners for the container */
  margin-bottom: 15px; /* Space below the search container */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); Add a subtle shadow */
}

.search-container label {
  margin-right: 10px; /* Space between label and input */
  font-weight: 600; /* Make label text bold */
}

.search-container input[type="text"] {
  width: 300px; /* Width of the input field */
  padding: 10px; /* Internal padding for the input */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Increase font size */
  transition: all 0.3s ease; /* Smooth transition for hover and focus states */
}

/* Hover effect for input */
.search-container input[type="text"]:hover {
  border-color: #888; /* Darker border on hover */
}

/* Focus effect for input */
.search-container input[type="text"]:focus {
  border-color: #007bff; /* Change border color on focus */
  outline: none; /* Remove default outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Subtle blue shadow */
}
.test-details-column {
  width: 800px; /* Adjust the width as needed */
  font-size: 12px; /* Make the text smaller */
}

.table__body {
  overflow-x: auto;
}

.table__body::-webkit-scrollbar {
  height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: rgba(12, 12, 12, 0.267);
  visibility: hidden;
} 

.table__body:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.received-button {
  background-color: #3da355; /* Green */
  color: #fff;
  border-radius: 10%;
}

.pending-button {
  background-color: #887641; /* Yellow */
  color: #fff;
  border-radius: 10%;
}
.received-button:hover {
  background-color: #21a841; /* Green */
  color: #fff;
  transform: scale(1.1)
}
.pending-button:hover {
  background-color: #9b7b1b; /* Yellow */
  color: #fff;
}
.email-status-cell {
  padding: 0 !important; /* Force padding to zero */
}

.email-Sent, .email-Pending {
  background-color: #28a745; /* Green for "Sent" */
  color: white; /* White text color */
  padding: 5px 10px; /* Adequate padding around text */
  border-radius: 10%; /* Rounded corners */
  text-align: center; /* Center text */
  font-weight: bold; /* Bold font */
  display: inline-block; /* Ensure inline-block for controlled size */
  margin: 0 auto; /* Center within parent */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: visible; /* Ensure text isn't cut off */
  text-overflow: clip; /* Prevent ellipsis */
  width: auto; /* Remove width restrictions */
  max-width: none; /* Ensure no max-width constraints */
  min-width: fit-content; /* Allow the width to fit the content */

}

.email-Pending {
  background-color:#887641; /* Red background for "Pending" status */
}

/* Modal backdrop */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures modal is above other content */
}

/* Modal content styling */
.modal-content {
  background: #fff; /* White background */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  width: 400px; /* Fixed width for the modal */
  max-width: 90%; /* Responsive width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center; /* Center align text */
}

/* Heading styling */
.modal-content h3 {
  margin-bottom: 20px; /* Space below heading */
  font-size: 18px;
  color: #333; /* Dark gray color */
}

/* Select dropdown styling */
.modal-content select {
  width: 100%; /* Full width */
  padding: 10px;
  margin-bottom: 20px; /* Space below select */
  border: 1px solid #ccc;
  border-radius: 4px; /* Rounded corners */
  font-size: 14px;
  outline: none; /* Remove default outline */
  transition: border-color 0.3s; /* Smooth transition */
}

.modal-content select:focus {
  border-color: #007bff; /* Blue border on focus */
}

/* Modal actions (buttons) styling */
.modal-actions {
  display: flex;
  justify-content: space-between; /* Space between buttons */
}

/* Button styling */
.modal-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px; /* Rounded corners */
  font-size: 14px;
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition */
}

/* Confirm button styling */
.modal-actions button:first-of-type {
  background-color: #007bff; /* Blue background */
  color: #fff; /* White text */
}

.modal-actions button:first-of-type:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Cancel button styling */
.modal-actions button:last-of-type {
  background-color: #e0e0e0; /* Light gray background */
  color: #333; /* Dark gray text */
}

.modal-actions button:last-of-type:hover {
  background-color: #ccc; /* Darker gray on hover */
}

/* Disable confirm button when loading or no userType selected */
.modal-actions button:disabled {
  background-color: #aaa; /* Gray background when disabled */
  cursor: not-allowed; /* Not allowed cursor */
}