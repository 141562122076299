/* General styles */
body {
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Modern font family */
    background-color: #fafafa; /* Lighter background for a fresher look */
    margin: 0;
    padding: 0;
    color: #333; /* Standard text color for better readability */
    line-height: 1.6; /* Improving line spacing */
}

/*Page heaer*/
.header{
    background: white;
    color: "white";
    font-size: "30px";
    margin-top: "0px";
    margin-bottom: "10px";
    color: #5f5c5c71;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Make the header fixed */
    top: 0; /* Fix it to the top of the page */
    width: 100%; /* Full width */
    z-index: 1000; /* Ensure it stays on top of other elements */
    padding: 10px 10px; /* Add some padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    
}
.imagePosition{
    position: absolute;
    top:2%;
    left:0%;
    width: 20%;
    height: 80%;
    padding: 10px;
   
   

}
.imagePosition img {
    width: 100%;
    height: 100%;
    /* box-shadow: 2px 6px 10px rgb(180, 176, 176); */
  }
  .headerWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .headerContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .headerContent h1 {
    margin: 0;
    text-shadow: 0px 1px 0.5px #615d5d;
  }
  
  .headerContent h2 {
    margin: 0;
    font-size: 20px;
    /* color: #7c7171; */
  }

/* Container styles */
.sales-form-container {
  /* margin-top: 100px; */
    max-width: 600px;
    margin: 50px auto;
    padding-bottom: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    background-color: #e7e3e3;
}

/* Header styles */
.sales-form-header {
  /* margin-top: 100px; */
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

/* Label styles */
.sales-form-group {
  display: flex;           /* Use flexbox for alignment */
  flex-direction: column;  /* Stack label on top of input */
  align-items: flex-start; /* Align items to the start */
  margin-bottom: 20px;     /* Space between input groups */
}
.sales-form-label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', "Calibri", 'Trebuchet MS', sans-serif;
    /* text-align: left */
 
}

/* Input styles */
.sales-form-input {
    width: 90%;
    padding: 10px;
    /* margin: 20px; */
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
   text-align: center;
}

/* Button styles */
.sales-form-button-Next {
    width: 20%;
    padding: 12px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 20px;
    text-align: center;
     /* margin-right: 120px; */
}
.sales-form-button-Submit {
    width: 100%;
    padding: 12px 20px;
    background-color: blue;
    color: rgb(228, 222, 222);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.sales-form-button-Next:hover {
    background-color: #0056b3;
}
.sales-form-button-Submit:hover {
    background-color: #060d6b;
}
.sales-form-button-back{
    width: 20%;
    padding: 12px 20px;
    background-color: #d8553e;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 20px;
    cursor: pointer;
    text-align: center;
  }
  
  .sales-form-button-back:hover {
    background-color: #ec0e19; /* Darker shade of blue on hover */
  }

/* Step indicator styles */
.sales-form-step {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
}

.sales-form-step-current {
    color: #007BFF;
    font-weight: bold;
}

.sales-form-step-total {
    color: #999;
}

.test-and-sample-container {
    position: relative;  
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}
    
.removetest-button {
    background-color: #dc3545; /* Danger color */
    color: white;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px; /* Increased size for better visibility */
    height: 28px;
    font-size: 20px;
    border-radius: 4px; 
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.2s;
}

.removetest-button:hover {
    background-color: #c82333; /* Darker red on hover */
    transform: scale(1.1); /* Slight scale effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.addtest-button {
    background-color: #007BFF; /* Primary blue color */
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effects */
    display: flex; /* Flexbox for aligning icon and text */
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center content horizontally */
    position:absolute;
    right:0
}

.addtest-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
    transform: translateY(-2px); /* Slight lift effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.addtest-button svg { /* FontAwesome icon styling */
    margin-left: 8px; /* Space between text and icon */
}

.footer {
    text-align: center;
    padding: 1px; /* Adequate padding for spacing */
    background-color: #f2f2f2; /* Subtle background color */
    border-top: 1px solid #e1e1e1; /* Light border for a soft separation from content */
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 14px;
    color: #666; /* Soft color for the text */
 
    margin: 0px;
}
.required {
    color: red;
    margin-left: 4px; /* Makes the asterisk red, standing out as a required indicator */
  }
  .error-message{
    color: red;
    padding-top: 0%;
    margin-top: 0%;
    font-style: italic;
    font-size: small;
    
  }
  .instructions-container{
    /* max-width: 1000px; */
    margin: 10px auto;
    padding-bottom: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 5px 10px 5px rgba(19, 18, 18, 0.05);
    margin-top: 0px;
    max-width: 1000px;
    height:400px;
    z-index:1000;
    padding: 10px;
    margin-top: 75px;

  }
  .agreementbutton{
    margin-top: 20px;
    width:150px;
    height:40px;
    font-weight:800px;
    font-size: 15px;
    cursor: pointer;
    background-color: #666;
    border: .5 px solid black;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
  }
  .agreementbutton.isAgreed{
    background-color: #007BFF;
    border: 1px solid black;
    color: white;
  }
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 60px;
    height: 60px;
    border: 6px solid #f3f3f3; /* Light grey background */
    border-top: 6px solid #3498db; /* Blue spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
/* Responsive adjustments */
@media (max-width: 990px) {
    .imagePosition {
        width: 15%;
        height: 80%;
    }
}
@media (max-width: 768px) {
    .sales-form-container {
        margin: 20px;
        padding: 15px;
    }

    .sales-form-label {
        font-size: 14px;
    }

    .sales-form-input {
        padding: 8px;
    }

    .sales-form-button {
        padding: 10px;
        font-size: 14px;
    }
    .sales-form-button-back{
        padding: 4%;
      
        font-size: 15px;
        text-align: center;
        letter-spacing: 0px;
        /* width: 50%; */
       
      }
      .sales-form-button-Next {
        padding: 4%;
        font-size: 15px;
        text-align: center;
        letter-spacing: 0px;
     
    }
    .footer {
        padding: 2px 2px; /* Slightly reduce padding on smaller screens */
        font-size: 12px; /* Smaller font size for smaller screens */
        margin: 0px;
    }


    .header {
        flex-direction: column;
        align-items: center;
        padding: 0px;
        margin-top: 0px;
      }
    
      .imagePosition {
        position: static; /* Remove absolute positioning */
        transform: none; /* Remove transform */
       padding-top: 5px;
       padding-bottom: 0px;
        margin-bottom: 2px; /* Add space below the image */
      }
    
      .headerWrapper {
        justify-content: center;
        width: 100%; /* Make sure the header text takes the full width */
      }
    
      .header h1 {
        font-size: 18px; /* Adjust the font size for mobile view */
        text-align: center; /* Center the text */
        margin-top: 0px;
      }
      .header h2 {
        font-size: 14px; /* Adjust the font size for mobile view */
        text-align: center; /* Center the text */
        margin-top: 0px;
      }
      .instructions-container {
        margin-top: 60px;
        max-width: 90%; /* Reduce the maximum width to fit smaller screens */
        height: auto; /* Allow the height to adjust based on the content */
        /* padding: 15px; Adjust padding for better spacing on mobile */
        margin-top: 20px; /* Reduce the top margin to use space more effectively */
        padding-top: 60px;
        font-size: medium;
      }
    
    
}
