/* NavBar.css - Premium World-class Styling with Advanced Design Features */

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(145deg, #333 0%, #1a1a1a 100%);
    backdrop-filter: blur(5px); /* Glassmorphic effect, works well with semi-transparent backgrounds */
    color: white;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* A more modern font-family */
    z-index: 1000;
    transition: background-color 0.3s ease;

    margin-bottom: 10px;
 
}

.navbar a {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    transition: color 0.3s ease-in-out, transform 0.3s ease-out, background-color 0.3s ease;
}

.navbar a:hover, .navbar a:focus {
    color: #EFEFEF;
    background-color: rgba(76, 175, 80, 0.8);
    border-radius: 8px;
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.navbar a.active {
    border-bottom: 4px solid #FFFFFF;
    background-color: rgba(51, 51, 51, 0.9);
}

/* Responsive design adjustments */
@media (max-width: 992px) {
    .navbar {
        padding: 10px;
    }
}

@media (max-width: 768px) {
    .navbar {
        display: flex;        /* Ensures flexbox is used */
        flex-direction: row;  /* Sets direction to row for multi-column */
        flex-wrap: wrap;      /* Allows items to wrap to the next line */
        justify-content: center; /* Aligns items to the start of the flex container */
        padding: 10px;
        align-items: center; /* Aligns items to the start of the container vertically */
        /* text-align: -webkit-auto; */
    }
    .navbar a {
        flex: 1 1 48%;       /* Each link should take up 50% of the width */
        box-sizing: border-box; /*Includes padding and border in the element's total width and height*/
        padding: 10px;       /* Ensures padding does not affect width calculation */
        margin-bottom: 10px;  /* Adds space between rows */
    }
}

/* Add this inside your existing NavBar.css file */
@media (min-width: 480px) and (max-width: 768px){
    .navbar .about-link {
        padding-right: 40%; /* Applies additional padding only to the About link */
    }
}

@media (max-width: 480px) {
    .navbar {
        flex-direction: column;
    }
    .navbar a {
        width: 100%; /* Full-width links in mobile view for easier access */
        margin-bottom: 5px;
    }
}
