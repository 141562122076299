/* CongratulationModal.css */
.congrats-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .congrats-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .congrats-modal-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .congrats-modal-button:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    .congrats-modal-content {
        padding: 15px; /* Reduce padding for smaller screens */
        font-size: 14px; /* Adjust font size for readability on smaller screens */
    }

    .congrats-modal-button {
        font-size: 14px; /* Slightly reduce button font size for smaller screens */
        padding: 10px; /* Adjust padding for a better fit on mobile */
    }
}
  