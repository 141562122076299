.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 90%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.contents {
    width: 80%;
    max-height: 80%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    overflow-y: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
