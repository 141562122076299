.login-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}

.login-left {
  background: #f0f0f0; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-right {
  background: linear-gradient(to right, #44054436, #6610f2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.login-form {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.feedback {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
}

.feedback.success {
  background-color: #d4edda;
  color: #155724;
}

.feedback.error {
  background-color: #f8d7da;
  color: #721c24;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.show-password {
  display: flex;
  align-items: center;
}

.show-password label {
  margin-left: 8px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.login-button:hover {
  background-color: #0056b3;
}
